import { createAction, props } from '@ngrx/store';
import * as sharedModels from '@shared/models';

const DICTIONARY = 'DICTIONARY';

export const getHouseCondition = createAction(`[${DICTIONARY}] Get House Condition`);
export const getHouseConditionSuccess = createAction(`[${DICTIONARY}] Get House Condition Success`, props<{ conditions: sharedModels.DictValue[] }>());
export const getHouseConditionFailure = createAction(`[${DICTIONARY}] Get House Condition Failure`, props<{ errorMessage: string }>());

export const getObjectType = createAction(`[${DICTIONARY}] Get Object Type`);
export const getObjectTypeSuccess = createAction(`[${DICTIONARY}] Get Object Type Success`, props<{ types: sharedModels.DictValue[] }>());
export const getObjectTypeFailure = createAction(`[${DICTIONARY}] Get Object Type Failure`, props<{ errorMessage: string }>());

export const updateCurrentHouseCondition = createAction(`[${DICTIONARY}] Update Current House Condition`, props<{ conditionIds: number[] }>());

export const updateCurrentObjectType = createAction(`[${DICTIONARY}] Update Current Object Type`, props<{ objectTypeId: number }>());